import React , {useState , useEffect , useRef} from 'react'
import { Box, Divider, Skeleton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import makeStyles from "@mui/styles/makeStyles";
import dayjs from 'dayjs';
import theme from 'theme';

import { RadioSelect } from 'components/CustomSelectFields';
import { NoResumeState, ResumeDetailBox } from 'components/Profile/Resume';
import { useServices } from 'contexts';
import { createPersonalizedTemplate, getDraftById, getResumes, getTemplates, saveResume } from 'services';
import { Session } from 'utils';
import { DocumentOptions, InterviewSteps, MockHeadingTemplate } from './constants';
import { useSnackbar } from "contexts";
import { useNavigateWithClient } from "hooks";
import CustomButton, { IconButton } from 'components/CustomButton';
import AppShell from 'components/AppShell';
import TextField from 'components/CustomFields';
import useStore from './store';
import Steps from './Steps';
import SelectJobDetails from './SelectJobDetails';
import StartPersonalizedAssessmentDialog from 'dialogs/StartPersonalizedAssessmentDialog';
import pdfToText from 'react-pdftotext';
import FileUpload from 'components/FileUpload';
import FileUploadErrorDialog from 'dialogs/FileUploadErrorDialog';
import ImageViewer from 'components/ImageViewer';
import CaseStudy from './CaseStudy';

const useStyles = makeStyles((theme) => ({
  createContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(11),
    width: '100%',
    background: `linear-gradient(278.27deg, ${theme.palette.primary.dark} 0%, #4B6CB7 112.71%)`,
    alignItems: 'center',
    padding: theme.spacing(7),
    borderRadius: theme.spacing(1),
    position: 'relative', 
    minHeight:`calc(100vh - 145px)`,
    height:`calc(100vh - 145px)`,
    [theme.breakpoints.down('1700')]: {
      height:'100%'
    }
},
  headContents: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '800px',
    gap: theme.spacing(3),
    textAlign: 'center',
},
  topRightImage: {
    position: 'absolute',
    top: '0px',
    right: '0px', 
},
  parentContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(8.5),
    backgroundColor : theme.palette.shades['clr-white-900'],
    padding:theme.spacing(7),
    borderRadius: '4px',
    marginBottom:theme.spacing(4),
    position:'relative',
    width:'800px'
},
  upperContents:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(8.5),
    zIndex: '10'
},
  heading:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(3),
},
  buttonContainer:{
    display:'flex',
    justifyContent:'space-between',
    gap:theme.spacing(3),
    zIndex: '10'
},
  bottomLeftImage:{
    position:'absolute',
    left:0,
    bottom:0,
    zIndex:0
},
  whiteBoxContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(7),
    alignItems:'center',
    padding:theme.spacing(7),
    backgroundColor : theme.palette.shades['clr-white-900'],
    borderRadius: '4px',
    position:'relative',
    width:'800px',
    height:'456px'
},
  whiteBoxContainerJobDetails:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(7),
    alignItems:'center',
    padding:theme.spacing(7),
    backgroundColor : theme.palette.shades['clr-white-900'],
    borderRadius: '4px',
    position:'relative',
    width:'800px'
},
  backArrow:{
    position:'absolute',
    left:'32px',
    top:'32px'
},
  selectResumeContainer : {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    gap:theme.spacing(3),
    position:'relative',
},
resumeBottomContainer:{
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  gap:theme.spacing(3)
},
uploadContainerBottom:{
  display:'flex',
  flexDirection:'column',
  position:'absolute',
  bottom: '-104px',
  zIndex:'10',
  boxShadow:'0px 0px 8px 0px #00000040',
  width:'800px',
  padding:theme.spacing(5,0),
  justifyContent:'center',
  alignItems:'center',
  gap:theme.spacing(2),
  backgroundColor:theme.palette.shades["clr-white-900"]
},
beginInterviewBottom:{
  display:'flex',
  flexDirection:'column',
  position:'absolute',
  bottom: '-104px',
  zIndex:'10',
  boxShadow:'0px 0px 8px 0px #00000040',
  width:'800px',
  padding:theme.spacing(6,0),
  justifyContent:'center',
  alignItems:'center',
  gap:theme.spacing(2),
  backgroundColor:theme.palette.shades["clr-white-900"]
},
selectResumeText:{
  width:'100%'
},
previewContainer:{
  display:'flex',
  flexDirection:'column',
  gap:theme.spacing(5),
  width:'480px'
},
detailsContainer:{
  display:'flex',
  flexDirection:'column',
  gap:theme.spacing(3)
},
mockDetailsContainer:{
  maxHeight:'235px',
  overflowY:'auto',
  width:'500px',
  display:'flex',
  justifyContent:'center'
},
mockDetailsBox:{
  display:'flex',
  flexDirection:'column',
  gap:theme.spacing(1)
},
mockDetail:{
  display:'flex',
  flexDirection:'row',
  gap:theme.spacing(5),
},
mockDetailText:{
  minWidth:'125px'
},
resumeDetailConatiner:{
  display:'flex',
  flexDirection:'column',
  gap:theme.spacing(2)
},
uploadedResumeBox: {
  padding: theme.spacing(2, 3),
  display: 'flex',
  gap: theme.spacing(3),
  border: '1px solid',
  borderColor: theme.palette.neutral['clr-400'],
  borderRadius: '4px',
  width: '480px',
},
uploadedResumeDetailsBox: {
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%'
},
topHeadBox: {
  display: 'flex',
  gap: theme.spacing(2)
},
head: {
  display: 'flex',
  flexDirection: 'row',
  gap:theme.spacing(2)
},
iconsBox: {
  display: 'flex',
  justifyContent: 'space-between',
  width: "100%"
},
noResumeContainer:{
  marginTop: theme.spacing(8),
  width:'480px'
},
previewBox:{
  height:'300px'
},
noResumeBox:{
  height:'224px'
},
jdPreviewBox:{
  height:'234px'
},
withoutResumeBox:{
  display:"flex",
  gap:theme.spacing(2),
  justifyContent:'center',
  alignItems:'center',
},
skeleton: {
  borderRadius: theme.spacing(3),
  width: '100%', height: '300px',
},
footHeadLast:{
  width: '250px',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
},
errorImage:{
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
},
textWrap: {
  width: '250px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
},
}));

const Preview = ({
  noResume , beginInterview , template , isDialogOpen , setIsDialogOpen , 
  isResponseReceived, clientMock , error
}) => {
  const classes = useStyles();
  const service = useServices();
  const hasSetNameRef = useRef(false);

  const [
          interviewName,selectedResumeDetails,selectedDocument,selectCompany,
          companyName,selectJobRole,jobRole,domain,jobDescription ,setInterviewName,
          setSelectCompany,setSelectJobRole
        ] = useStore(store => [
              store.interviewName,store.selectedResumeDetails,store.selectedDocument,
              store.selectCompany,store.companyName,store.selectJobRole,store.jobRole,
              store.domain,store.jobDescription,store.setInterviewName,store.setSelectCompany,
              store.setSelectJobRole
            ])

  const [errorInput, setErrorInput] = useState(false);
 
  useEffect(() => {
    (async () => {
      if (!clientMock && !hasSetNameRef.current) {
        try {
          let name;
          if (selectedDocument === DocumentOptions.RESUME._id) {
            name = selectJobRole?.name || jobRole;
          } else {
            name = `${selectCompany?.name || companyName}: ${selectJobRole?.name || jobRole}`;
          }
  
          const filter = { name: name, isActive: true, user: Session.userId };
          const { interviewTemplates } = await service.callService(getTemplates, filter);
  
          let newInterviewName = name.length > 150 ? name.substring(0, 150) : name;
          let suffix = 1;

          const nameExists = (nameToCheck) => {
            return interviewTemplates.some(template => template.name === nameToCheck);
          };
  
          while (nameExists(newInterviewName)) {
            const paddedSuffix = suffix.toString().padStart(2, '0');
            newInterviewName = `${name}-${paddedSuffix}`;
            suffix++;
          }
  
          setInterviewName(newInterviewName);
          hasSetNameRef.current = true;
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, []);  
  

  useEffect(()=> {
    if(companyName && jobRole){
      setSelectCompany(companyName)
      setSelectJobRole(jobRole)
    }
   if(selectedDocument === DocumentOptions.RESUME._id && !selectJobRole){
    if(jobRole){
      setSelectJobRole(jobRole)
    }
   }
  },[])

  const handleChangeInput = (event) => {
    setInterviewName(event.target.value)
  }
  
  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleInterviewBegin = () => {
    beginInterview()
  }

  const isButtonDisabled = () => {
    if(selectedDocument === DocumentOptions.RESUME._id){
      return !interviewName || !selectJobRole || !domain 
    } else {
      return !interviewName || !selectCompany || !selectJobRole || !domain || !jobDescription
  }
}
  
  return (
      <Box className={
        selectedDocument === DocumentOptions.JD._id 
          ? classes.jBoxdPreviewBox 
          : selectedResumeDetails ? classes.previewBox : classes.noResumeBox
        }
      >
        <Box className={classes.selectResumeContainer }>
          <Box className={classes.detailsContainer}>
            <Box className={classes.mockDetailsContainer}>
              <Box 
                sx={{width:'480px',  display:'flex', flexDirection:'column', gap:theme.spacing(2)}}
              >
                <Typography variant='h6-medium' color='neutral.clr-800'>
                    Mock Interview details
                </Typography>
                {selectedDocument === DocumentOptions.RESUME._id 
                  ? (
                      <Box className={classes.mockDetailsBox}>
                        <Box className={classes.mockDetail}>
                          <Typography 
                            variant='body01-medium' 
                            color='neutral.clr-600' 
                            className={classes.mockDetailText}
                            >
                              Job role:
                          </Typography>
                          <Typography variant='body01-semiBold'>
                            {selectJobRole.name || jobRole}
                          </Typography>
                        </Box>
                        <Box className={classes.mockDetail}>
                            <Typography 
                              variant='body01-medium' 
                              color='neutral.clr-600' 
                              className={classes.mockDetailText}
                              >
                                Domain:
                            </Typography>
                            <Typography variant='body01-semiBold'>{domain.name}</Typography>
                        </Box>
                      </Box>
                  ) 
                  : 
                  (
                    <Box className={classes.mockDetailsBox}>
                      <Box className={classes.mockDetail}>
                        <Typography 
                          variant='body01-medium' 
                          color='neutral.clr-600' 
                          className={classes.mockDetailText}
                          >
                            Company name:
                        </Typography>
                        <Typography variant='body01-semiBold'>
                          {selectCompany.name || companyName}
                        </Typography>
                      </Box>
                      <Box className={classes.mockDetail}>
                          <Typography 
                            variant='body01-medium' 
                            color='neutral.clr-600' 
                            className={classes.mockDetailText}
                            >
                              Job role:
                          </Typography>
                          <Typography variant='body01-semiBold'>
                            {selectJobRole.name || jobRole}
                          </Typography>
                      </Box>
                    </Box>
                  )}
                <TextField 
                  label={clientMock ? 'Interview name' : 'Enter interview name'}
                  placeholder=' Ex. Data analyst'
                  value={interviewName}
                  onChange={handleChangeInput}
                  inputProps={{ maxLength: 45 }}
                  error={errorInput}
                  helperText={errorInput ? 'Interview name cannot be empty' : ''}
                  disabled={clientMock === true}
                />    
                  {
                    selectedDocument !== DocumentOptions.JD._id && 
                    (
                      <>
                        {selectedResumeDetails && (
                          <>
                            <Divider />
                          
                            <Box className={classes.resumeDetailConatiner}>
                              <Typography variant='h6-medium' color='neutral.clr-800'>
                                Selected Resume
                              </Typography>
                              <Box className={classes.uploadedResumeBox}>
                                <img
                                  src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/resumeUploaded.png'
                                  alt='resume'
                                />
                                  <Box className={classes.uploadedResumeDetailsBox}>
                                    <Box className={classes.topHeadBox}>
                                      <Box className={classes.head}>
                                        <Typography
                                          className={classes.resumeName} variant='body01-medium' 
                                          color='neutral.clr-900'
                                        >
                                              Resume name:
                                        </Typography>
                                        <Typography
                                          className={classes.textWrap} variant='body01-semiBold'>
                                              {selectedResumeDetails?.name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Divider variant='light' />
                                    <Box className={classes.head}>
                                      <span>
                                        <Typography variant='body01-medium' color='neutral.clr-900'>
                                            Created on:
                                        </Typography>
                                      </span>
                                      <span>
                                        <Typography variant='body01-semiBold'>
                                          {dayjs(selectedResumeDetails?.createdOn).format('DD/MM/YYYY')}
                                        </Typography>
                                      </span>
                                    </Box>
                                    <Box className={classes.head}>
                                      <span>
                                        <Typography variant='body01-medium' color='neutral.clr-900'>
                                          Last used for:
                                        </Typography>
                                      </span>
                                      <span className={classes.footHeadLast}>
                                        <Typography variant='body01-semiBold'>
                                          {selectedResumeDetails?.lastUsedFor?.name || 'NA'}
                                        </Typography>
                                      </span>
                                    </Box>
                                  </Box>
                              </Box>
                            </Box>
                          </>
                        )}
                      </>
                    )
                  }
              </Box>
            </Box>
          </Box>
          <Box className={classes.beginInterviewBottom}>
            <CustomButton
              variant='contained'
              sx={{ width:'488px'}}
              onClick={handleInterviewBegin}
              size='medium'
              disabled={isButtonDisabled()}
            >
              Begin Interview
            </CustomButton>
          </Box>
        </Box>
        <StartPersonalizedAssessmentDialog 
          openDialog={isDialogOpen} 
          onClose={handleDialogClose} 
          responseReceived={isResponseReceived}
          template={template}
          error={error}
          noResume={noResume}
        />
      </Box>
    )}

const SelectResume = ({ next, stepNumber, setIsNoResume, clientMock }) => {
    const classes = useStyles();
    const service = useServices();
    const snackbar = useSnackbar();

    const [
            selectedResumeId,setSelectedResumeDetails,setParsedData,setSelectedResumeId
          ] = useStore((store)=> [
                                  store.selectedResumeId,store.setSelectedResumeDetails,
                                  store.setParsedData,store.setSelectedResumeId
                                ])
  
    const [resumes, setResumes] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [selectedBox, setSelectedBox] = useState(null);
    const [loading , setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState({ name: undefined, url: "" });
    const [error, setError] = useState(false);
    const [fileName , setFileName] = useState('')
    const [resumeNames, setResumeNames] = useState([]);
    const [url , setUrl] = useState('')
    const [scrapedData , setScrapedData] = useState('')
    const [isFileUploaded , setIsFileUploaded] = useState(false)
    const [progressBar , setProgressBar] = useState(false)
    const [fileNameReceived , setFileNameReceived] = useState(false)
  
    useEffect(() => {
      const saveUploadedResume = async () => {
        if (isFileUploaded) {
          const resumeData = {
            name: fileName,
            url: url,
            parsedData: scrapedData,
            user: Session.userId,
          };
          try {
            const result = await service.callService(saveResume, resumeData);
            setSelectedResumeId(result._id);
            setIsFileUploaded(false);
            setError(false);
          } catch (error) {
            console.log(error);
            setError(true);
            setFileNameReceived(false);
            snackbar.error("Uh Oh! Unable to save resume");
            setIsFileUploaded(false);
          }
        }
      };

      saveUploadedResume();
    }, [isFileUploaded]);

    useEffect(()=> {
      if(scrapedData && fileName && url){
        setIsFileUploaded(true)
      }
    },[scrapedData , fileName , url])
    
    const handleUpload = (uploadedFile) => {
      if (!uploadedFile ) {
        setError(true); 
        return; 
      }
      setUrl(uploadedFile.url)
      setSelectedFile(uploadedFile);
     
    };
  
    const handleUploadCancelled = () => {
      setError(true)
      setSelectedFile({ name: undefined, url: "" });
    };
  
    const handlePdfScrape = async(selectedFile) => {
      
      const scrapedData = await extractResumeData(selectedFile);
      if(scrapedData){
        setScrapedData(scrapedData)
        let fileNameWithoutExtension = selectedFile.name.replace(/\.pdf$/i, '');

        fileNameWithoutExtension = generateUniqueFileName(fileNameWithoutExtension, resumeNames);
    
        setFileName(fileNameWithoutExtension)
        setFileNameReceived(true)

        return true;
      }
    }
  
    const extractResumeData = async (file) => {
      try {
        const extractedText = await pdfToText(file);
        
        if (!extractedText || extractedText.trim().length === 0) {
          setError(true)
          return null;
        }else{
          return extractedText;
        }
      } catch (error) {
        setError(true)
        console.error("Failed to extract text from PDF:", error);
        return null;
      }
    };

    const generateUniqueFileName = (baseName, existingFileNames) => {
      if (existingFileNames.length === 0) {
        return baseName;
      }
  
      let uniqueName = baseName;
      let counter = 1;
      const existingNamesSet = new Set(existingFileNames);
  
      while (existingNamesSet.has(uniqueName)) {
        uniqueName = `${baseName}-${String(counter).padStart(2, '0')}`;
        counter++;
      }
  
      return uniqueName;
    };

    useEffect(() => {
      const latestResume = resumes
        .filter(resume => resume.lastUsedForTemplate)
        .sort((a, b) => dayjs(b.updatedAt).diff(dayjs(a.updatedAt)))[0];
    
      if (latestResume) {
        setSelectedResumeId(latestResume?._id) 
        setSelectedBox(resumes.indexOf(latestResume)); 
        setSelectedResumeDetails({
          name: latestResume.name,
          createdOn: latestResume.createdAt,
          lastUsedFor: latestResume?.lastUsedForTemplate,
        });
      }
      if (selectedResumeId && resumes.length > 0) {
        const resumeIndex = resumes.findIndex((resume) => resume._id === selectedResumeId);
        
        if (resumeIndex !== -1) {
          handleBoxClick(resumeIndex);
        }
      }
      
    }, [resumes]);
  
    const handleBoxClick = (index) => {
      const selectedResume = resumes[index];
      
      setSelectedBox(index);
      setSelectedResumeDetails({
        name: selectedResume.name,
        createdOn: selectedResume.createdAt,
        lastUsedFor: selectedResume?.lastUsedForTemplate,
      });
      setParsedData(selectedResume.parsedData);
      setSelectedResumeId(selectedResume?._id) 
    };
  
    useEffect(() => {
      if (refresh) {
        fetchResume();
        setRefresh(false);
      }
    }, [refresh]);
  
    async function fetchResume() {
      try {
        setLoading(true);

        const response = 
          await service.callService(getResumes, { user: Session.userId,include: ['interviewTemplate'] });

        const sortedResumes = response.resumes.sort(
            (a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt))
          );

        setResumes(sortedResumes);

        const names = sortedResumes.map((resume) => resume.name);
        setResumeNames(names);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false)
      }
    }

    const handleNextStep = () => {
      next(stepNumber + 1);
    };
  
    const handleNextStepWithoutResume = () => {
      next(stepNumber + 1);
      setSelectedResumeDetails(null);
      setIsNoResume(true);
      setParsedData(null);
    };
  
    return (
      <>
        <Box className={classes.selectResumeContainer}>
          {
            loading 
              ? 
                <Box style={{ width: '100%'}}>
                  <Skeleton variant="rectangular" className={classes.skeleton} />
                </Box> 
              :
                resumes.length > 0 
                ?
                  (
                    <>
                      <Box className={classes.selectResumeText}>
                        <Typography variant="h6-medium">Select Resume</Typography>
                      </Box>
                      <ResumeDetailBox
                        resumes={resumes}
                        setRefresh={setRefresh}
                        height="200px"
                        selectedBox={selectedBox}
                        setSelectedBox={setSelectedBox}
                        onBoxClick={handleBoxClick}
                        personalizedInterview={true}
                        fileName={fileName}
                        fileNameReceived={fileNameReceived}
                        setFileNameReceived={setFileNameReceived}
                        isFileUploaded={isFileUploaded}
                        error={error}
                        progressBar={progressBar}
                        setProgressBar={setProgressBar}
                      />
                      <Box className={classes.uploadContainerBottom}>
                        <Box className={classes.resumeBottomContainer}>
                        <FileUpload 
                          name="thumbnail"
                          label="Upload file"
                          file={selectedFile}
                          onUpload={handleUpload}
                          onUploadCancelled={handleUploadCancelled}
                          toPublic={true}
                          uploadLocation="pdf/resume"
                          accept={['application/pdf']} 
                          onFileSelect={handlePdfScrape}
                          uploadOnAws={true}
                          variant='outlined'
                          sx={{width:'234px'}}
                          setErrors={setError}
                        />
                          <CustomButton
                            variant="contained"
                            sx={{ width: '234px' }}
                            onClick={handleNextStep}
                            disabled={selectedBox === null}
                          >
                            Next
                          </CustomButton>
                        </Box>
                        {clientMock && (
                          <Box className={classes.withoutResumeBox}>
                            <Typography
                              onClick={handleNextStepWithoutResume}
                              variant="body01-link"
                              color="primary.clr-300"
                              sx={{cursor:'pointer'}}
                            >
                              Continue without resume
                            </Typography>
                            <Typography variant="body01-medium" color="neutral.clr-500">
                              (Personalization is not possible)
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </>
                  ) 
                : 
                  (
                    <Box className={classes.noResumeContainer}>
                      <NoResumeState
                        nextStep={handleNextStepWithoutResume}
                        buttonText="Add new resume"
                        buttonWidth="100%"
                        clientMock={clientMock}
                        progressBar={progressBar}
                        fileName={fileName}
                        isFileUploaded={isFileUploaded}
                        error={error}
                        setRefresh={setRefresh}
                        fileNameReceived={fileNameReceived}
                        setProgressBar={setProgressBar}
                        setFileNameReceived={setFileNameReceived}
                        fileUploadButton={ <FileUpload 
                                              name="thumbnail"
                                              label="Add New Resume"
                                              file={selectedFile}
                                              onUpload={handleUpload}
                                              onUploadCancelled={handleUploadCancelled}
                                              toPublic={true}
                                              uploadLocation="pdf/resume"
                                              accept={['application/pdf']} 
                                              onFileSelect={handlePdfScrape}
                                              uploadOnAws={true}
                                              sx={{
                                                width: '234px',
                                                opacity: progressBar ? 0 : 1,
                                              }}
                                              setErrors={setError}
                                          />}
                                  />
                                </Box>
                              )}
         <FileUploadErrorDialog
            open={error}
            fileUploadProp={  <FileUpload 
                                name="thumbnail"
                                label="Upload again"
                                file={selectedFile}
                                onUpload={handleUpload}
                                onUploadCancelled={handleUploadCancelled}
                                toPublic={true}
                                uploadLocation="pdf/resume"
                                accept={['application/pdf']} 
                                onFileSelect={handlePdfScrape}
                                uploadOnAws={true}
                                buttonSize='medium'
                                sx={{width:'50%'}}
                                setErrors={setError}
                              />}
            title={<Box color='danger.clr-700'>Resume upload failed!</Box>}
            message={
                      <Box className={classes.errorImage}>
                      <ImageViewer 
                        src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/Error_Resume_Save.png' 
                        alt='delete' 
                        width={170}
                        height={170}
                      />
                      </Box>}
            secondaryAction="I don’t want to upload"
            secondaryActionType="outlined" 
            buttonSize='medium'
            onSecondaryAction={() => {
              setError(null);
            }}
          />
        </Box>
      </>
    );
};
    

const SelectDocumentOption = ({
  handleInterviewStep,
  setOpenEnterCompanyField , 
  setOpenCaseStudy,
  setOpenSelectDocumentOptions
}) => {
  const classes = useStyles();
  const navigate = useNavigate()

  const selectedDocument =  useStore(store => store.selectedDocument);
  const setSelectedDocument =  useStore(store => store.setSelectedDocument);

  useEffect(()=>{
    setOpenEnterCompanyField(false)
  },[])

  const handleChangeRadio = (event) => {
    setSelectedDocument(event.target.value)
  };

  const handleClosePage = () => {
    navigate(-1)
  }

  const handleCreate = () => {
    if(selectedDocument === DocumentOptions.CASE_STUDY._id){
      setOpenCaseStudy(true)
      setOpenSelectDocumentOptions(false)
    }else{
      handleInterviewStep()
    }
  }

  return (
    <Box className={classes.parentContainer}>
      <Box className={classes.upperContents}>
        <Box className={classes.heading}>
          <Typography variant='h5-medium' color='neutral.clr-900'>
            How would you like to practice?
          </Typography>
          <Divider />
        </Box>

        <Box className={classes.select}>
            <RadioSelect 
              options={Object.values(DocumentOptions)}
              value={selectedDocument}
              onChange={handleChangeRadio}
              sx={{ backgroundColor:theme.palette.shades['clr-white-900'] }}
            />
        </Box>                 
      </Box>
      <Box className={classes.buttonContainer}>
        <CustomButton 
          variant='outlined' 
          size='medium' 
          style={{width:'100%' , backgroundColor:theme.palette.shades['clr-white-900']}}
          onClick={handleClosePage}
        >
            Go Back
        </CustomButton>
        <CustomButton 
          variant='contained' 
          size='medium' 
          style={{width:'100%'}}
          onClick={handleCreate}
        >
            Proceed
        </CustomButton>
      </Box>
      <img 
        src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/BottomLeftCircle.svg'
        alt='circle'
        className={classes.bottomLeftImage}
      />
  </Box>
  )
}

const CreateInterview = () => {
  const classes = useStyles();
  const theme = useTheme()
  const service = useServices();
  const navigateWithClient = useNavigateWithClient();
  const snackbar = useSnackbar();
  const { id } = useParams()

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [interviewError , setInterviewError] = useState(false)
  const [openEnterCompanyField , setOpenEnterCompanyField] = useState(false)
  const [clientMock , setClientMock] = useState(false)
  const [isNoResume , setIsNoResume] = useState(false)

  const [
          activeStep,selectCompany,selectJobRole,companyName,
          jobRole,jobDescription,domain,selectedResumeDetails,
          selectedResumeId,templateDetails,draft,interviewName,
          setActiveStep,setSelectedDocument
        ] = useStore(store => [
              store.activeStep,store.selectCompany,store.selectJobRole,store.companyName,
              store.jobRole,store.jobDescription,store.domain,store.selectedResumeDetails,
              store.selectedResumeId,store.templateDetails,store.draft,store.interviewName,
              store.setActiveStep,store.setSelectedDocument
            ])

  const init = useStore(store => store.init);

  const [openSelectDocumentOptions , setOpenSelectDocumentOptions] = useState(true)
  const [stepNumber , setStepNumber] = useState(0)
  const [assessmentDialogOpen , setAssessmentDialogOpen] = useState(false)
  const [ isResponseReceived , setIsResponseReceived] = useState(false)
  const [template , setTemplate] = useState()
  const [isDataChanged , setIsDataChanged] = useState(false)
  const [openCaseStudy , setOpenCaseStudy] = useState(false)

  useEffect(()=>{
    if(id !=='create'){

      setOpenSelectDocumentOptions(false)
      setOpenEnterCompanyField(true)
      setSelectedDocument(DocumentOptions.JD_RESUME._id)
      setActiveStep(InterviewSteps.SELECT_RESUME._id)
      setStepNumber(1)
      setClientMock(true)

      const fetchDraft = async () => {
        try {
          const response = await service.callService(getDraftById , id);
          const fetchedDraft = response?.data?.INTERVIEW_DETAILS
          init({
            companyName : fetchedDraft.company,
            jobRole : fetchedDraft.jobRole,
            domain : fetchedDraft.domain,
            jobDescription : fetchedDraft.jobDescription,
            templateDetails : {
              batch :fetchedDraft.batch,
              publishOn :fetchedDraft.publishOn,
              expiresOn:fetchedDraft.expiresOn,
              proctorSettings:{...fetchedDraft.proctorSettings}
            },
            draft : response?._id,
            interviewName : fetchedDraft.name
          })
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchDraft();
    }
  },[])

  const handleInterviewStep = () => {
    setOpenSelectDocumentOptions(false)
    init( activeStep,
      selectCompany,
      selectJobRole,
      companyName,
      jobRole,
      jobDescription,
      domain,
      selectedResumeDetails)
}

const handleBeginInterview = async () => {
  try {
    setInterviewError(false)
    setAssessmentDialogOpen(true)
    const dataToSend = {};
    dataToSend.domain = domain._id || domain;

    if (selectCompany || companyName) {
      dataToSend.company = selectCompany?.name || selectCompany;
    }

    dataToSend.jobRole = selectJobRole?.name || selectJobRole || jobRole;

    if (jobDescription) {
      dataToSend.jobDescription = jobDescription;
    }

    if(draft){
      dataToSend.draft = draft
    }

    if (selectedResumeId) {
      dataToSend.resume = selectedResumeId;
    }

    dataToSend.templateDetails = {
      name: interviewName,
    };

    if(templateDetails){
      dataToSend.templateDetails = {
        name: interviewName,
        batch : templateDetails.batch,
        publishOn : templateDetails.publishOn,
        expiresOn: templateDetails.expiresOn,
        proctorSettings: templateDetails.proctorSettings
      }
    }
    
    const response = await service.callService(createPersonalizedTemplate, { ...dataToSend });
    
    if(response){
      setIsResponseReceived(true)
      setTemplate(response.interviewTemplate)
    }
  } catch (err) {
    console.error("Error while creating personalized template:", err.message);
    snackbar.error(err.message);
    setInterviewError(true)
  }
};

  const handleBackButton = () => {
    if(clientMock){
      if(stepNumber===0){
        navigateWithClient('/home')
      }
    }

    if(stepNumber){
      setStepNumber(stepNumber - 1)
    }else{
      setOpenSelectDocumentOptions(true)
    }
  };

  return (
    <AppShell headerLabel='Interview Wizard'>
      <Box className={classes.createContainer}>
        <Box className={classes.headContents}>
          <Typography variant='h3-medium' color='shades.clr-white-900'>
          {openSelectDocumentOptions || openCaseStudy
            ? MockHeadingTemplate.selectDocument.head
            :  clientMock 
              ? MockHeadingTemplate.clientMock.head
              : MockHeadingTemplate.personalizedMock.head
          }
          </Typography>
          <Typography variant='h6-medium' color='primary.clr-100'>
            {openSelectDocumentOptions 
              ? null
              : openCaseStudy 
                ? null
                : clientMock 
                  ? MockHeadingTemplate.clientMock.subHead
                  : MockHeadingTemplate.personalizedMock.subHead
            }
          </Typography>
        </Box>
        <img 
          src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/TopRightArrow.png' 
          alt='arr' 
          className={classes.topRightImage} 
        />
        {openSelectDocumentOptions 
          ? 
            (
              <SelectDocumentOption 
                handleInterviewStep={handleInterviewStep}  
                setOpenEnterCompanyField={setOpenEnterCompanyField}
                setOpenCaseStudy={setOpenCaseStudy}
                setOpenSelectDocumentOptions={setOpenSelectDocumentOptions}
              />
            ) 
          : openCaseStudy 
          ? (
              <CaseStudy
              setOpenSelectDocumentOptions={setOpenSelectDocumentOptions} 
              setOpenCaseStudy={setOpenCaseStudy}
              />
            )
          :  activeStep
          ?
            (
              <Box 
                className={InterviewSteps.SELECT_RESUME._id  === activeStep 
                  ? classes.whiteBoxContainer 
                  : classes.whiteBoxContainerJobDetails
                }
              >
                <IconButton
                  variant='contained'
                  style={{backgroundColor:theme.palette.shades['clr-white-900'] , 
                          color:theme.palette.neutral.main}}
                  className={classes.backArrow}
                  onClick={handleBackButton}
                >
                    <img 
                      src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/arrow-left-icon.png'  
                      alt='back'
                    />
                </IconButton>

                <Steps 
                  stepNumber={stepNumber} 
                  setStepNumber={setStepNumber} 
                  setIsDataChanged={setIsDataChanged} 
                  isDataChanged={isDataChanged}
                />

                {activeStep === InterviewSteps.JOB_DETAILS._id && 
                  (
                    <SelectJobDetails 
                      stepNumber={stepNumber} 
                      next={setStepNumber} 
                      openEnterCompanyField={openEnterCompanyField}
                      setOpenEnterCompanyField={setOpenEnterCompanyField}
                      clientMock={clientMock}
                      setIsDataChanged={setIsDataChanged}
                    />
                  )}
                {activeStep === InterviewSteps.SELECT_RESUME._id && 
                  (
                    <SelectResume 
                      stepNumber={stepNumber} 
                      next={setStepNumber} 
                      setIsNoResume={setIsNoResume}
                      clientMock={clientMock}
                    />
                  )}
                {activeStep === InterviewSteps.PREVIEW._id && 
                  (
                    <Preview 
                      noResume={isNoResume} 
                      beginInterview={handleBeginInterview} 
                      template={template} 
                      isResponseReceived={isResponseReceived} 
                      isDialogOpen={assessmentDialogOpen} 
                      setIsDialogOpen={setAssessmentDialogOpen}
                      clientMock={clientMock}
                      error={interviewError}
                    />
                  )} 
              </Box>
            )
            : null}     
      </Box>
    </AppShell>
  );
};

export default CreateInterview;
